import './styles.css';
// @ts-expect-error 7016
import resize from '@alpinejs/resize';
import * as Sentry from '@sentry/browser';
import Alpine from 'alpinejs';
import PineconeRouter from 'pinecone-router';
import posthog from 'posthog-js';
import { Route, routes } from './routes.ts';
import { getApiHost, handleVersion, keepAlive } from './services/api.service.ts';

declare global {
    interface Window {
        Alpine: typeof Alpine;
        apiHost: string;
    }
}

export type ComponentModule = {
    name: () => string;
    styles?: () => Promise<any>;
    template: () => Promise<{ default: string }>;
    data: () => any;
};

export const render = async (module: any, route: string | undefined = undefined) => {
    const componentModule: ComponentModule = typeof module['default'] !== 'undefined' ? module.default : module;
    const name = componentModule.name === undefined ? '' : componentModule.name();

    Alpine.data(name, componentModule.data);

    if (componentModule.styles) {
        await componentModule.styles();
    }

    if (componentModule.template === undefined) {
        console.warn('Template not found!', componentModule);
        return '';
    }

    const componentTemplate = await componentModule.template();
    const html = `<div x-data="${name}">${componentTemplate.default}</div>`;

    return route ? `<template x-route="${route}">${html}</template>` : html;
};

async function main() {
    let html: string = '';

    await Promise.all(
        routes.map(async (route: Route) => {
            const { path, component } = route;
            const content = await render(await component(), path);

            html += content;
        }),
    );

    // Toast component
    const toast = await import('@/components/shared/toast/toast.ts');
    html += await render(toast);

    return Promise.resolve(html);
}

const defineApiHost = (): void => {
    console.info('MODE', import.meta.env?.MODE);

    // Development specific settings
    if (import.meta.env?.MODE === 'development') {
        console.log('Development environment');
        window.apiHost = '';
    }

    // Production specific settings
    if (import.meta.env?.MODE !== 'development') {
        window.apiHost = getApiHost();

        // Sentry
        Sentry.init({
            dsn: 'https://0825b8d53e7694ba827b2c18d224c96b@o4508256865812480.ingest.de.sentry.io/4508256869613648',
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                /^https?:\/\/([a-z0-9]+\.)*isiparts\.com/,
                /^https?:\/\/([a-z0-9]+\.)*isiparts\.pages\.dev/,
            ],
            // Session Replay
            replaysSessionSampleRate: 1.0, //  Capture 100% of the transactions
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        // Posthog
        posthog.init('phc_3y02C4cuEDWvSdN1HfhBJ7x4eSOooPJVPirZh6azFNt', {
            api_host: 'https://eu.i.posthog.com',
            person_profiles: 'identified_only',
        });
    }
};

main().then((html) => {
    defineApiHost();

    const app = document.querySelector<HTMLDivElement>('#app');

    if (!app) {
        console.warn('#app not found!');
        return;
    }

    app.innerHTML = html;

    Alpine.plugin(PineconeRouter);
    Alpine.plugin(resize);
    // Add more Alpine plugins here

    window.Alpine = Alpine;

    Alpine.start();

    keepAlive();
    handleVersion();
});

// document.addEventListener('alpine:init', () => {
//     window.PineconeRouter.settings.basePath = document.head.baseURI.endsWith('/')
//         ? document.head.baseURI
//         : `${document.head.baseURI}/`;
// });
