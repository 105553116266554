export type Route = {
    path: string;
    component: () => Promise<any>;
};

export const routes: Route[] = [
    {
        path: '/',
        component: () => import('@/components/pages/home/home.ts'),
    },
    {
        path: '/login',
        component: () => import('@/components/pages/login/login.ts'),
    },
    {
        path: '/or/list',
        component: () => import('@/components/pages/work-orders/work-orders.ts'),
    },
    {
        path: '/or/me',
        component: () => import('@/components/pages/work-orders/work-orders.ts'),
    },
    {
        path: '/or/new',
        component: () => import('@/components/pages/work-order-new/work-order-new.ts'),
    },
    {
        path: '/or/:or/details',
        component: () => import('@/components/pages/work-order/work-order.ts'),
    },
    {
        path: '/presunto/new',
        component: () => import('@/components/pages/presales/presale-new/presale-new.ts'),
    },
    {
        path: '/presunto/modify',
        component: () => import('@/components/pages/presales/presale-list/presale-list.ts'),
    },
    {
        path: '/presunto/modify/:pre',
        component: () => import('@/components/pages/presales/presale-edit/presale-edit.ts'),
    },
    {
        path: 'notfound',
        component: () => import('@/components/pages/404/404.ts'),
    },
];
