export const SIGNATURE_CLEAR_ALL = 'signature:clear-all';
export const SIGNATURE_SAVE = 'signature:save';

export const DRAWER_TOGGLE = 'drawer:toggle';

export const REFERENCE_INIT = 'reference:init';
export const REFERENCE_FOUND = 'reference:found';
export const REFERENCE_NOT_FOUND = 'reference:not-found';
export const REFERENCE_CLEAR = 'reference:clear';
export const REFERENCE_GET = 'reference:get';

export const TOAST_TRIGGER = 'toast:trigger';

export const PAGE_VISIT = 'pinecone-end'; // PineCone router specific event
